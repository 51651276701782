<template>
  <div class="center-home">
    <div class="top-panel">
      <div class="left">
        <div class="info">
          <div class="avatar">
            <img src="http://dummyimage.com/80x80/4b5cc4" alt="" srcset="" />
          </div>
          <div class="name-btn">
            <div class="name">
              深圳XXXXXXX技术有限公司
              <i class="el-icon-s-custom"></i>
            </div>
            <div class="btn-wrap">
              <i class="el-icon-edit-outline"></i>
              修改个人资料
            </div>
          </div>
        </div>
        <div class="bal">
          <div>
            <span class="no">0.00</span>
            <span>元</span>
          </div>
          <div>总金额</div>
        </div>
      </div>
      <div class="right">
        <div class="tip">安全等级：高</div>
        <div class="tags">
          <span>
            <i class="el-icon-mobile-phone"></i>
          </span>
          <span>
            <i class="el-icon-lock"></i>
          </span>
          <span>
            <i class="el-icon-key"></i>
          </span>
          <span>
            <i class="el-icon-s-custom"></i>
          </span>
        </div>
        <div class="line-wrap">
          <div class="line"></div>
        </div>

        <span class="more-btn">
          <i class="el-icon-arrow-right"></i>
        </span>
      </div>
    </div>

    <div class="middle-panel">
      <div class="left">
        <div class="section-box">
          <div class="title">
            <span>我的报名</span>
          </div>
          <div class="content-wrap">
            <div class="desc">暂无报名</div>
            <div class="tip-btn">
              <span>您还没有参加大赛活动，快去参加吧</span>
              <el-button style="width: 140px" type="primary" size="medium"
                >去报名</el-button
              >
            </div>
          </div>
        </div>
        <div class="section-box">
          <div class="title">
            <span>我的项目</span>
          </div>
          <div class="content-wrap">
            <div class="desc">暂无报名</div>
            <div class="tip-btn">
              <span>您还没有参加大赛活动，快去参加吧</span>
              <el-button style="width: 140px" type="primary" size="medium"
                >去报名</el-button
              >
            </div>
          </div>
        </div>
        <div class="section-box">
          <div class="title">
            <span>我的需求</span>
          </div>
          <div class="content-wrap">
            <div class="desc">暂无报名</div>
            <div class="tip-btn">
              <span>您还没有参加大赛活动，快去参加吧</span>
              <el-button style="width: 140px" type="primary" size="medium"
                >去报名</el-button
              >
            </div>
          </div>
        </div>
      </div>
      <div class="right">456</div>
    </div>
    <!-- <div class="bottom-panel">789</div> -->
  </div>
</template>

<script>
export default {
  name: "CenterHome",
};
</script>

<style lang="scss" scoped>
.center-home {
  .top-panel {
    margin-bottom: 10px;
    background-color: #fff;
    height: 120px;
    width: 100%;
    box-sizing: border-box;
    padding: 15px 0;

    display: flex;
    // align-items: flex-start;

    .left {
      flex: 1 1 auto;
      padding: 5px 0;
      padding-left: 20px;

      display: flex;
      justify-content: space-between;

      .info {
        display: flex;
        align-items: flex-start;
        .avatar {
          width: 80px;
          height: 80px;
          overflow: hidden;
          border-radius: 50%;
        }

        .name-btn {
          margin-left: 20px;
          margin-top: 12px;
          .name {
            vertical-align: middle;
            font-size: 16px;
            font-weight: 700;
            color: #333333;
            i {
              font-size: 18px;
              color: red;
              padding-left: 8px;
            }
          }

          .btn-wrap {
            font-size: 14px;
            color: red;
            margin-top: 10px;
            vertical-align: middle;
            i {
              padding-right: 4px;
            }
          }
        }
      }

      .bal {
        margin-right: 70px;
        margin-top: 15px;
        & > div:nth-child(2) {
          margin-top: 5px;
          font-size: 14px;
          font-weight: 400;
          color: #666666;
        }

        & > div:nth-child(1) {
          font-size: 16px;
          line-height: 19px;
          font-weight: 700;
          color: #666666;
          .no {
            font-size: 20px;
            font-weight: 700;
            text-align: left;
            color: #e62d31;
            margin-right: 5px;
            line-height: 24px;
          }
        }
      }
    }

    .right {
      flex: 0 0 auto;
      position: relative;
      width: 265px;
      padding: 5px 35px 5px 30px;
      box-sizing: border-box;
      border-left: 2px solid #f5f5f5;
      font-size: 14px;
      .tip {
        margin-top: 5px;
        margin-left: 10px;
        font-size: 14px;
        font-weight: 400;
      }
      .tags {
        margin-top: 5px;
        color: #fff;
        span {
          width: 20px;
          height: 20px;
          // padding: 2px;
          line-height: 20px;
          text-align: center;
          display: inline-block;
          background-color: #18c275;
          border-radius: 50%;
          margin: 4px;
        }
      }

      .line-wrap {
        margin-top: 5px;
        width: 174px;
        height: 4px;
        background: #dddddd;
        border-radius: 2px;

        position: absolute;
        bottom: 4px;

        .line {
          width: 100%;
          height: 4px;
          opacity: 1;
          background: #ff850a;
          border-radius: 2px;
          box-shadow: 0px 1px 5px 0px rgb(194 70 24 / 14%);
        }
      }

      .more-btn {
        position: absolute;
        top: 50%;
        right: 35px;
        margin-top: -9px;
        color: #999;
        i {
          font-size: 18px;
        }
      }
    }
  }

  .middle-panel {
    background-color: #fff;
    display: flex;

    .left {
      flex: 1 1 auto;
      padding: 15px 20px;

      .section-box + .section-box {
        margin-top: 20px;
      }

      .section-box {
        .title {
          padding-left: 8px;

          line-height: 14px;
          height: 14px;
          font-size: 16px;
          font-weight: bold;
          border-left: 2px solid red;
          span {
            vertical-align: middle;
          }
        }
        .content-wrap {
          margin-top: 10px;
          height: 190px;
          padding: 60px 60px 0 20px;
          border: 1px solid #f5f5f5;
          .desc {
            font-size: 16px;
            font-weight: 700;
            color: #333333;
            line-height: 24px;
          }

          .tip-btn {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #333;
            font-size: 14px;
          }
        }
      }
    }

    .right {
      flex: 0 0 auto;
      width: 265px;
      position: relative;
      border-left: 2px solid #f5f5f5;
      padding: 20px 15px;
    }
  }
}
</style>